"use client"

import clsx from "clsx"

import type { ExplicitAny } from "~/@types/generics"
import ErrorHandler from "~/components/ui/ErrorHandler"

import { neue, secondFont } from "~/styles/fonts"
import { themeClass } from "~/styles/theme.css"
import { customVariablesNames } from "~/styles/variables/customs"

export default function GlobalError({ error, reset }: { error: Error; reset: () => void }) {
  const theme = clsx(themeClass, secondFont.className, secondFont.variable, neue.className, neue.variable)

  return (
    <html>
      <body
        className={theme}
        style={{
          [customVariablesNames.scrollBar as ExplicitAny]: "0px",
          [customVariablesNames.scrollBarGrid as ExplicitAny]: "0px",
          [customVariablesNames.stableVh as ExplicitAny]: "1vh",
          [customVariablesNames.vh as ExplicitAny]: "1vh",
          [customVariablesNames.vh100 as ExplicitAny]: "100vh",
        }}
      >
        <ErrorHandler error={error} reset={reset} title="A global error occurred" />
      </body>
    </html>
  )
}
